<template>
  <div v-loading="boxLoading">
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教研中心</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: '/evaluation/details/' + $route.params.id }"
          >测评详情</el-breadcrumb-item
        >
        <el-breadcrumb-item>配置列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ grade }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="margin-sm">
      <div v-for="item in list" :key="item.id" class="topic-list">
        <!-- 单选题 --><!-- 多选题 -->
        <el-card v-if="item.type === 1 || item.type === 2" class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ item.order }}.</span>
            <span v-html="item.content"></span>
          </div>
          <div v-for="(o, i) in item.items" :key="o.id" class="option">
            <span class="select"
              >{{ topic[i] }}.
              <span v-if="item.text_type === 1" v-html="o.item"></span>
              <span v-else>
                <img
                  :src="imageUrl + o.item"
                  alt=""
                  height="50px"
                  width="50px"
                />
              </span>
            </span>
            <span
              v-for="(v, index) in o.options"
              :key="index"
              class="select"
              style="margin-left: 20px"
            >
              <span>{{ type[v.type - 1] }}</span>
              <span>
                分数：
                <el-input-number
                  v-model="v.score"
                  :min="0"
                  size="mini"
                ></el-input-number
              ></span>
            </span>
          </div>
          <div>
            是否拥有答案：
            <el-switch
                v-model="item.has_answer"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-card>

        <!-- 判断题 -->
        <el-card v-if="item.type === 3" class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ item.order }}.</span>
            <span v-html="item.content"></span>
          </div>
          <div v-for="o in item.items" :key="o.id" class="option">
            <span>{{ o.item }}</span>
            <span
              v-for="(v, index) in o.options"
              :key="index"
              class="select"
              style="margin-left: 20px"
            >
              <span>{{ type[v.type - 1] }}</span>
              <span>
                分数：
                <el-input-number
                  v-model="v.score"
                  :min="0"
                  size="mini"
                ></el-input-number
              ></span>
            </span>
          </div>
          <div>
            是否拥有答案：
            <el-switch
                v-model="item.has_answer"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-card>

        <!-- 填空题 -->
        <!-- <el-card class="box-card" v-if="item.type == 4">
                    <div slot="header" class="clearfix">
                        <span>{{ item.order }}.</span>
                        <span v-html="item.content"></span>
                    </div>
                    <div v-for="o in item.items" :key="o.id" class="option">
                        <span v-html="o.item"></span>
                        <span class="select" v-for="(v, index) in o.options" :key="index" style="margin-left: 20px;">
                            <span>{{ type[v.type - 1] }}</span>
                            <span>
                                分数：
                                <el-input-number v-model="v.score" :min="0" size="mini"></el-input-number></span>
                        </span>
                    </div>
                </el-card> -->
      </div>

      <div class="save">
        <el-button :loading="loading" type="primary" @click="save"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";
export default {
  name: "configuration",
  data() {
    return {
      loading: false,
      boxLoading: false,
      imageUrl: config.imageUrl,
      id: null,
      list: [],
      topic: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      type: ["自主阅读", "综合理解", "信息提取", "潜在信推断"],
      grade: "",
    };
  },
  methods: {
    ...mapActions("evaluation", [
      "getEvaluationItemOptions",
      "saveEvaluationItemOptions",
      "getEvaluationItem",
    ]),
    async init() {
      this.boxLoading = true;
      const { data } = await this.getEvaluationItemOptions({ id: this.id });
      const res = await this.getEvaluationItem({ id: this.id });
      this.grade = res.data.grade;
      console.log(data);
      this.list = data;
      this.boxLoading = false;
    },
    async save() {
      this.loading = true;
      let form = {
        id: this.id,
        data: this.list,
      };
      const { res_info } = await this.saveEvaluationItemOptions(form);

      this.loading = false;
      if (res_info != "ok") return;
      this.$message.success("保存成功！");
    },
  },
  mounted() {
    this.id = this.$route.params.classId;
    this.init();
  },
};
</script>

<style scoped>
.option {
  line-height: 30px;
  display: flex;
  margin-bottom: 10px;
}

.save {
  margin-top: 10px;
  width: 100%;
  text-align: right;
}

.clearfix {
  display: flex;
}

.select {
  display: flex;
  align-items: center;
}

.box-card {
  margin-top: 20px;
}
</style>
